import isNil from 'lodash/isNil';
import isArray from 'lodash/isArray';
import { FormField } from '../types';

/**
 * Check if a form field value has not been set.
 * It is considered to be set if it is different from null, undefined or ''.
 *
 * @param value The value to check.
 */
export const notSet = (value: FormField) => (
  isNil(value) || value === '' || (isArray(value) && value.length === 0)
);

export const lastDayOfMonth = (year: number, month: number) => (
  new Date(year, month + 1, 0).getDate()
);
