import {
  Vue,
  Ref,
  Emit,
  Prop,
  Component,
} from 'vue-property-decorator';
import { ValidationRules, VForm } from '@/app/core/types';

@Component
export default class FormMixin extends Vue {
  @Prop({ type: Boolean, default: false }) loading!: boolean;

  protected internalLoading = false;

  protected formValid = true;

  protected data: unknown;

  protected rules: ValidationRules = {};

  get isLoading() {
    return this.loading || this.internalLoading;
  }

  @Ref('form') readonly form!: VForm;

  @Emit('submit')
  submit() {
    return this.data;
  }

  afterSubmit() {
    // Overwrite to do something after submitting
  }

  validate() {
    return this.form.validate();
  }

  reset() {
    return this.form.reset();
  }

  resetValidation() {
    return this.form.resetValidation();
  }

  onSubmit() {
    if (this.validate()) {
      this.submit();
      this.afterSubmit();
    }
  }
}
