import i18n from '@/plugins/i18n';
import { FormField } from '@/app/core/types';
import { notSet } from '../util';

/**
 * Email validation rule.
 *
 * @param value The value that must an email.
 */
export const email = (value: FormField) => {
  const regexp = /^[a-zá-ú0-9]+([-._][a-zá-ú0-9]+)*@([a-zá-ú0-9]+(-[a-zá-ú0-9]+)*\.)+[a-zá-ú]{2,}$/;
  const valid = notSet(value) || regexp.test(String(value));
  return valid || i18n.t('validation.email');
};

/**
 * Min length validation rule.
 *
 * @param threshold The value to compare with.
 */
export const minLength = (threshold: number) => {
  if (threshold < 1) throw new Error('threshold must be at least 1');

  return (value: FormField) => {
    const valid = notSet(value) || String(value).length >= threshold;
    return valid || i18n.t('validation.minLength', { threshold });
  };
};

/**
 * Max length validation rule.
 *
 * @param threshold The value to compare with.
 */
export const maxLength = (threshold: number) => {
  if (threshold < 1) throw new Error('threshold must be at least 1');

  return (value: FormField) => {
    const valid = notSet(value) || String(value).length <= threshold;
    return valid || i18n.t('validation.maxLength', { threshold });
  };
};

/**
 * Exact length validation rule.
 *
 * @param threshold The value to compare with.
 */
export const exactLength = (threshold: number) => {
  if (threshold < 1) throw new Error('threshold must be at least 1');

  return (value: FormField) => {
    const valid = notSet(value) || String(value).length === threshold;
    return valid || i18n.t('validation.exactLength', { threshold });
  };
};

/**
 * Alpha (only letters) validation rule.
 *
 * @param allowSpaces Whether spaces are allowed.
 */
export const alpha = (allowSpaces = false) => (
  (value: FormField) => {
    const regexp = allowSpaces ? /^[a-zá-ú ]*$/i : /^[a-zá-ú]*$/i;
    const valid = notSet(value) || regexp.test(String(value));
    return valid || i18n.t(`validation.alpha${allowSpaces ? 'Spaces' : ''}`);
  }
);

/**
 * Numeric (only numbers) validation rule.
 *
 * @param allowSpaces Whether spaces are allowed.
 */
export const numeric = (allowSpaces = false) => (
  (value: FormField) => {
    const regexp = allowSpaces ? /^[0-9 ]*$/i : /^[0-9]*$/i;
    const valid = notSet(value) || regexp.test(String(value));
    return valid || i18n.t(`validation.numeric${allowSpaces ? 'Spaces' : ''}`);
  }
);

/**
 * AlphaNumeric (only letters and numbers) validation rule.
 *
 * @param allowSpaces Whether spaces are allowed.
 */
export const alphaNumeric = (allowSpaces = false) => (
  (value: FormField) => {
    const regexp = allowSpaces ? /^[a-zá-ú0-9 ]*$/i : /^[a-zá-ú0-9]*$/i;
    const valid = notSet(value) || regexp.test(String(value));
    return valid || i18n.t(`validation.alphaNumeric${allowSpaces ? 'Spaces' : ''}`);
  }
);

/**
 * At least 'x' characters of 'y' type validation rules.
 *
 * @param x The number of characters of a certain type that at least must be present.
 */
export const atLeast = (x = 1) => {
  if (x < 1) throw new Error('x must be at least 1');

  return {
    digits: (value: FormField) => {
      const regexp = new RegExp(`(?=(.*[0-9]){${x},})`);
      const valid = notSet(value) || regexp.test(String(value));
      return valid || i18n.tc('validation.atLeast.digits', x);
    },
    lowercaseLetters: (value: FormField) => {
      const regexp = new RegExp(`(?=(.*[a-zá-ú]){${x},})`);
      const valid = notSet(value) || regexp.test(String(value));
      return valid || i18n.tc('validation.atLeast.lowercase', x);
    },
    uppercaseLetters: (value: FormField) => {
      const regexp = new RegExp(`(?=(.*[A-ZÁ-Ú]){${x},})`);
      const valid = notSet(value) || regexp.test(String(value));
      return valid || i18n.tc('validation.atLeast.uppercase', x);
    },
    specialChars: (value: FormField) => {
      const regexp = new RegExp(`(?=(.*[!@#$%^&*.]){${x},})`);
      const valid = notSet(value) || regexp.test(String(value));
      return valid || i18n.tc('validation.atLeast.special', x);
    },
  };
};
