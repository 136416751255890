import { TranslateResult } from 'vue-i18n';
import i18n from '@/plugins/i18n';
import { FormField } from '@/app/core/types';
import { notSet } from '../util';

/**
 * Required validation rule.
 *
 * @param value The value that is required.
 */
export const required = (value: FormField) => {
  const valid = !notSet(value);
  return valid || i18n.t('validation.required');
};

/**
 * Equals to validation rule.
 * The validation it is type sensitive ('3' is not equal to 3).
 *
 * Important: This validation rule requires reactivity to work properly
 * because it depends on an external reactive value (the to parameter),
 * this implies using it as or inside a computed property.
 *
 * @param to The value.
 * @param to The value to compare with.
 * @param toLabel The label of the value to compare with.
 */
export const equalTo = (
  value: FormField,
  to: FormField,
  toLabel: string | TranslateResult,
) => {
  const valid = notSet(value) || value === to;
  return valid || i18n.t('validation.equalTo', { to: toLabel });
};
