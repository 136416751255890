import i18n from '@/plugins/i18n';
import { FormField } from '@/app/core/types';
import { notSet } from '../util';

/**
 * Lower than validation rule.
 *
 * @param threshold The value to compare with.
 */
export const lt = (threshold: number) => (
  (value: FormField) => {
    const valid = notSet(value) || Number(value) < threshold;
    return valid || i18n.t('validation.lt', { threshold });
  }
);

/**
 * Lower than or equals validation rule.
 *
 * @param threshold The value to compare with.
 */
export const lte = (threshold: number) => (
  (value: FormField) => {
    const valid = notSet(value) || Number(value) <= threshold;
    return valid || i18n.t('validation.lte', { threshold });
  }
);

/**
 * Greater than validation rule.
 *
 * @param threshold The value to compare with.
 */
export const gt = (threshold: number) => (
  (value: FormField) => {
    const valid = notSet(value) || Number(value) > threshold;
    return valid || i18n.t('validation.gt', { threshold });
  }
);

/**
 * Greater than or equals validation rule.
 *
 * @param threshold The value to compare with.
 */
export const gte = (threshold: number) => (
  (value: FormField) => {
    const valid = notSet(value) || Number(value) >= threshold;
    return valid || i18n.t('validation.gte', { threshold });
  }
);
